import QRCode from "qrcode";

type TextConfig = {
  fontSize: number;
  x: number;
  y: number;
};

type QrConfig = {
  x: number;
  y: number;
  size: number;
  iconSize: number;
};

type ImageConfig = {
  id: number;
  label: string;
  color: string;
  corporateName: TextConfig;
  title: TextConfig;
  name: TextConfig;
  lineWith: number;
  email: TextConfig;
  tel: TextConfig;
  free: TextConfig;
  qr1: QrConfig;
  qr2: QrConfig;
};

const ichimaiiwa: Omit<ImageConfig, "id" | "label"> = {
  color: "#fff",
  corporateName: {
    fontSize: 32,
    x: 70,
    y: 130,
  },
  title: {
    fontSize: 32,
    x: 70,
    y: 180,
  },
  name: {
    fontSize: 48,
    x: 70,
    y: 280,
  },
  lineWith: 350,
  email: {
    fontSize: 28,
    x: 70,
    y: 350,
  },
  tel: {
    fontSize: 28,
    x: 70,
    y: 400,
  },
  free: {
    fontSize: 28,
    x: 70,
    y: 450,
  },
  qr1: {
    x: 1450,
    y: 100,
    size: 400,
    iconSize: 80,
  },
  qr2: {
    x: 1450,
    y: 550,
    size: 400,
    iconSize: 80,
  },
};

export const imageConfigs: ImageConfig[] = [
  {
    id: 1,
    label: "ネイビーなかっこいい背景（Nateeロゴ）",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 110,
      y: 320,
    },
    title: {
      fontSize: 32,
      x: 110,
      y: 370,
    },
    name: {
      fontSize: 48,
      x: 110,
      y: 470,
    },
    lineWith: 350,
    email: {
      fontSize: 28,
      x: 110,
      y: 540,
    },
    tel: {
      fontSize: 28,
      x: 110,
      y: 590,
    },
    free: {
      fontSize: 28,
      x: 110,
      y: 640,
    },
    qr1: {
      x: 1450,
      y: 100,
      size: 400,
      iconSize: 80,
    },
    qr2: {
      x: 1450,
      y: 550,
      size: 400,
      iconSize: 80,
    },
  },
  {
    id: 2,
    label: "ホワイトなかっこいい背景（Nateeロゴ）",
    color: "#000",
    corporateName: {
      fontSize: 32,
      x: 110,
      y: 320,
    },
    title: {
      fontSize: 32,
      x: 110,
      y: 370,
    },
    name: {
      fontSize: 48,
      x: 110,
      y: 470,
    },
    lineWith: 350,
    email: {
      fontSize: 28,
      x: 110,
      y: 540,
    },
    tel: {
      fontSize: 28,
      x: 110,
      y: 590,
    },
    free: {
      fontSize: 28,
      x: 110,
      y: 640,
    },
    qr1: {
      x: 1450,
      y: 100,
      size: 400,
      iconSize: 80,
    },
    qr2: {
      x: 1450,
      y: 550,
      size: 400,
      iconSize: 80,
    },
  },
  {
    id: 3,
    label: "ネイビーなかっこいい背景",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 110,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 110,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 110,
      y: 270,
    },
    lineWith: 350,
    email: {
      fontSize: 28,
      x: 110,
      y: 340,
    },
    tel: {
      fontSize: 28,
      x: 110,
      y: 390,
    },
    free: {
      fontSize: 28,
      x: 110,
      y: 440,
    },
    qr1: {
      x: 1450,
      y: 100,
      size: 400,
      iconSize: 80,
    },
    qr2: {
      x: 1450,
      y: 550,
      size: 400,
      iconSize: 80,
    },
  },
  {
    id: 4,
    label: "ホワイトなかっこいい背景",
    color: "#000",
    corporateName: {
      fontSize: 32,
      x: 110,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 110,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 110,
      y: 270,
    },
    lineWith: 350,
    email: {
      fontSize: 28,
      x: 110,
      y: 340,
    },
    tel: {
      fontSize: 28,
      x: 110,
      y: 390,
    },
    free: {
      fontSize: 28,
      x: 110,
      y: 440,
    },
    qr1: {
      x: 1450,
      y: 100,
      size: 400,
      iconSize: 80,
    },
    qr2: {
      x: 1450,
      y: 550,
      size: 400,
      iconSize: 80,
    },
  },
  {
    id: 5,
    label: "ステポジ1",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 40,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 40,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 40,
      y: 270,
    },
    lineWith: 300,
    email: {
      fontSize: 24,
      x: 40,
      y: 340,
    },
    tel: {
      fontSize: 24,
      x: 40,
      y: 390,
    },
    free: {
      fontSize: 24,
      x: 40,
      y: 440,
    },
    qr1: {
      x: 1590,
      y: 410,
      size: 300,
      iconSize: 60,
    },
    qr2: {
      x: 1590,
      y: 750,
      size: 300,
      iconSize: 60,
    },
  },
  {
    id: 6,
    label: "ステポジ2",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 40,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 40,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 40,
      y: 270,
    },
    lineWith: 300,
    email: {
      fontSize: 24,
      x: 40,
      y: 340,
    },
    tel: {
      fontSize: 24,
      x: 40,
      y: 390,
    },
    free: {
      fontSize: 24,
      x: 40,
      y: 440,
    },
    qr1: {
      x: 1590,
      y: 410,
      size: 300,
      iconSize: 60,
    },
    qr2: {
      x: 1590,
      y: 750,
      size: 300,
      iconSize: 60,
    },
  },
  {
    id: 7,
    label: "ステポジ3",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 40,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 40,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 40,
      y: 270,
    },
    lineWith: 300,
    email: {
      fontSize: 24,
      x: 40,
      y: 340,
    },
    tel: {
      fontSize: 24,
      x: 40,
      y: 390,
    },
    free: {
      fontSize: 24,
      x: 40,
      y: 440,
    },
    qr1: {
      x: 1590,
      y: 410,
      size: 300,
      iconSize: 60,
    },
    qr2: {
      x: 1590,
      y: 750,
      size: 300,
      iconSize: 60,
    },
  },
  {
    id: 8,
    label: "ステポジ4",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 40,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 40,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 40,
      y: 270,
    },
    lineWith: 300,
    email: {
      fontSize: 24,
      x: 40,
      y: 340,
    },
    tel: {
      fontSize: 24,
      x: 40,
      y: 390,
    },
    free: {
      fontSize: 24,
      x: 40,
      y: 440,
    },
    qr1: {
      x: 1590,
      y: 410,
      size: 300,
      iconSize: 60,
    },
    qr2: {
      x: 1590,
      y: 750,
      size: 300,
      iconSize: 60,
    },
  },
  {
    id: 9,
    label: "ステポジ5",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 40,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 40,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 40,
      y: 270,
    },
    lineWith: 300,
    email: {
      fontSize: 24,
      x: 40,
      y: 340,
    },
    tel: {
      fontSize: 24,
      x: 40,
      y: 390,
    },
    free: {
      fontSize: 24,
      x: 40,
      y: 440,
    },
    qr1: {
      x: 1590,
      y: 410,
      size: 300,
      iconSize: 60,
    },
    qr2: {
      x: 1590,
      y: 750,
      size: 300,
      iconSize: 60,
    },
  },
  {
    id: 10,
    label: "ステポジ6",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 40,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 40,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 40,
      y: 270,
    },
    lineWith: 300,
    email: {
      fontSize: 24,
      x: 40,
      y: 340,
    },
    tel: {
      fontSize: 24,
      x: 40,
      y: 390,
    },
    free: {
      fontSize: 24,
      x: 40,
      y: 440,
    },
    qr1: {
      x: 1590,
      y: 410,
      size: 300,
      iconSize: 60,
    },
    qr2: {
      x: 1590,
      y: 750,
      size: 300,
      iconSize: 60,
    },
  },
  {
    id: 11,
    label: "ステポジ7",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 40,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 40,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 40,
      y: 270,
    },
    lineWith: 300,
    email: {
      fontSize: 24,
      x: 40,
      y: 340,
    },
    tel: {
      fontSize: 24,
      x: 40,
      y: 390,
    },
    free: {
      fontSize: 24,
      x: 40,
      y: 440,
    },
    qr1: {
      x: 1590,
      y: 410,
      size: 300,
      iconSize: 60,
    },
    qr2: {
      x: 1590,
      y: 750,
      size: 300,
      iconSize: 60,
    },
  },
  {
    id: 12,
    label: "ステポジ8",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 40,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 40,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 40,
      y: 270,
    },
    lineWith: 300,
    email: {
      fontSize: 24,
      x: 40,
      y: 340,
    },
    tel: {
      fontSize: 24,
      x: 40,
      y: 390,
    },
    free: {
      fontSize: 24,
      x: 40,
      y: 440,
    },
    qr1: {
      x: 1590,
      y: 410,
      size: 300,
      iconSize: 60,
    },
    qr2: {
      x: 1590,
      y: 750,
      size: 300,
      iconSize: 60,
    },
  },
  {
    id: 13,
    label: "ステポジ9",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 40,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 40,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 40,
      y: 270,
    },
    lineWith: 300,
    email: {
      fontSize: 24,
      x: 40,
      y: 340,
    },
    tel: {
      fontSize: 24,
      x: 40,
      y: 390,
    },
    free: {
      fontSize: 24,
      x: 40,
      y: 440,
    },
    qr1: {
      x: 1590,
      y: 410,
      size: 300,
      iconSize: 60,
    },
    qr2: {
      x: 1590,
      y: 750,
      size: 300,
      iconSize: 60,
    },
  },
  {
    id: 14,
    label: "ステポジ10",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 40,
      y: 120,
    },
    title: {
      fontSize: 32,
      x: 40,
      y: 170,
    },
    name: {
      fontSize: 48,
      x: 40,
      y: 270,
    },
    lineWith: 300,
    email: {
      fontSize: 24,
      x: 40,
      y: 340,
    },
    tel: {
      fontSize: 24,
      x: 40,
      y: 390,
    },
    free: {
      fontSize: 24,
      x: 40,
      y: 440,
    },
    qr1: {
      x: 1590,
      y: 410,
      size: 300,
      iconSize: 60,
    },
    qr2: {
      x: 1590,
      y: 750,
      size: 300,
      iconSize: 60,
    },
  },
  {
    id: 15,
    label: "ネイビーなかっこいい背景（ATFロゴ）",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 110,
      y: 320,
    },
    title: {
      fontSize: 32,
      x: 110,
      y: 370,
    },
    name: {
      fontSize: 48,
      x: 110,
      y: 470,
    },
    lineWith: 350,
    email: {
      fontSize: 28,
      x: 110,
      y: 540,
    },
    tel: {
      fontSize: 28,
      x: 110,
      y: 590,
    },
    free: {
      fontSize: 28,
      x: 110,
      y: 640,
    },
    qr1: {
      x: 1450,
      y: 100,
      size: 400,
      iconSize: 80,
    },
    qr2: {
      x: 1450,
      y: 550,
      size: 400,
      iconSize: 80,
    },
  },
  {
    id: 16,
    label: "ホワイトなかっこいい背景（ATFロゴ）",
    color: "#000",
    corporateName: {
      fontSize: 32,
      x: 110,
      y: 320,
    },
    title: {
      fontSize: 32,
      x: 110,
      y: 370,
    },
    name: {
      fontSize: 48,
      x: 110,
      y: 470,
    },
    lineWith: 350,
    email: {
      fontSize: 28,
      x: 110,
      y: 540,
    },
    tel: {
      fontSize: 28,
      x: 110,
      y: 590,
    },
    free: {
      fontSize: 28,
      x: 110,
      y: 640,
    },
    qr1: {
      x: 1450,
      y: 100,
      size: 400,
      iconSize: 80,
    },
    qr2: {
      x: 1450,
      y: 550,
      size: 400,
      iconSize: 80,
    },
  },
  {
    id: 17,
    label: "ネイビーなかっこいい背景（WOWSロゴ）",
    color: "#fff",
    corporateName: {
      fontSize: 32,
      x: 110,
      y: 320,
    },
    title: {
      fontSize: 32,
      x: 110,
      y: 370,
    },
    name: {
      fontSize: 48,
      x: 110,
      y: 470,
    },
    lineWith: 350,
    email: {
      fontSize: 28,
      x: 110,
      y: 540,
    },
    tel: {
      fontSize: 28,
      x: 110,
      y: 590,
    },
    free: {
      fontSize: 28,
      x: 110,
      y: 640,
    },
    qr1: {
      x: 1450,
      y: 100,
      size: 400,
      iconSize: 80,
    },
    qr2: {
      x: 1450,
      y: 550,
      size: 400,
      iconSize: 80,
    },
  },
  {
    id: 18,
    label: "ホワイトなかっこいい背景（WOWSロゴ）",
    color: "#000",
    corporateName: {
      fontSize: 32,
      x: 110,
      y: 320,
    },
    title: {
      fontSize: 32,
      x: 110,
      y: 370,
    },
    name: {
      fontSize: 48,
      x: 110,
      y: 470,
    },
    lineWith: 350,
    email: {
      fontSize: 28,
      x: 110,
      y: 540,
    },
    tel: {
      fontSize: 28,
      x: 110,
      y: 590,
    },
    free: {
      fontSize: 28,
      x: 110,
      y: 640,
    },
    qr1: {
      x: 1450,
      y: 100,
      size: 400,
      iconSize: 80,
    },
    qr2: {
      x: 1450,
      y: 550,
      size: 400,
      iconSize: 80,
    },
  },
  {
    ...ichimaiiwa,
    id: 19,
    label: "一枚岩 1",
  },
  {
    ...ichimaiiwa,
    id: 20,
    label: "一枚岩 2",
  },
  {
    ...ichimaiiwa,
    id: 21,
    label: "一枚岩 3",
  },
  {
    ...ichimaiiwa,
    id: 22,
    label: "一枚岩 4",
  },
  {
    ...ichimaiiwa,
    id: 23,
    label: "一枚岩 5",
  },
];

type GruopedImageConfig = {
  label: string;
  configs: ImageConfig[];
};

export const groupedImageConfigs: GruopedImageConfig[] = [
  {
    label: "Natee",
    configs: [1, 2].map(
      (id) => imageConfigs.find((config) => config.id === id)!
    ),
  },
  {
    label: "WOWs",
    configs: [17, 18].map(
      (id) => imageConfigs.find((config) => config.id === id)!
    ),
  },
  {
    label: "ATF",
    configs: [15, 16].map(
      (id) => imageConfigs.find((config) => config.id === id)!
    ),
  },
  {
    label: "無印",
    configs: [3, 4].map(
      (id) => imageConfigs.find((config) => config.id === id)!
    ),
  },
  {
    label: "一枚岩",
    configs: [19, 20, 21, 22, 23].map(
      (id) => imageConfigs.find((config) => config.id === id)!
    ),
  },
  {
    label: "ステポジ",
    configs: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(
      (id) => imageConfigs.find((config) => config.id === id)!
    ),
  },
];

type Params = {
  id: number;
  corporateName: string;
  title: string;
  name: string;
  email: string;
  tel: string;
  free: string;
  qrCode1: string;
  qrCode2: string;
};

export const createImageData = async (params: Params): Promise<string> => {
  const { id, corporateName, title, name, email, tel, free, qrCode1, qrCode2 } =
    params;

  const canvas = document.getElementById(`canvas`) as HTMLCanvasElement;
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Context does not exists.");
  }

  const imageConfig = imageConfigs.find((config) => config.id === id);
  const textColor = imageConfig?.color || "#fff";

  const img = await createImageAsync(`/background-${id}.jpg`);

  // 準備完了したので描画する
  context.restore();
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.save();

  // 背景を描画
  if (img) {
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
  }

  drawText(
    context,
    corporateName,
    imageConfig?.corporateName.fontSize || 32,
    imageConfig?.corporateName.x || 110,
    imageConfig?.corporateName.y || 320,
    textColor
  );

  drawText(
    context,
    title,
    imageConfig?.title.fontSize || 32,
    imageConfig?.title.x || 110,
    imageConfig?.title.y || 370,
    textColor
  );

  drawText(
    context,
    name,
    imageConfig?.name.fontSize || 48,
    imageConfig?.name.x || 110,
    imageConfig?.name.y || 470,
    textColor
  );

  drawLine(
    context,
    textColor,
    imageConfig?.name.x || 110,
    (imageConfig?.name.y || 470) + 20,
    imageConfig?.lineWith || 350
  );

  drawText(
    context,
    email,
    imageConfig?.email.fontSize || 28,
    imageConfig?.email.x || 110,
    imageConfig?.email.y || 540,
    textColor
  );

  drawText(
    context,
    tel,
    imageConfig?.tel.fontSize || 28,
    imageConfig?.tel.x || 110,
    imageConfig?.tel.y || 590,
    textColor
  );

  drawText(
    context,
    free,
    imageConfig?.free.fontSize || 28,
    imageConfig?.free.x || 110,
    imageConfig?.free.y || 640,
    textColor
  );

  if (qrCode1) {
    const config = imageConfig?.qr1;
    await drawQrCode(
      context,
      qrCode1,
      config?.x || 1450,
      config?.y || 100,
      config?.size || 400,
      config?.iconSize || 80
    );
  }

  if (qrCode2) {
    const config = imageConfig?.qr2;
    await drawQrCode(
      context,
      qrCode2,
      config?.x || 1450,
      config?.y || 550,
      config?.size || 400,
      config?.iconSize || 80
    );
  }

  // base64にして返す
  return canvas.toDataURL("image/jpg");
};

const createImageAsync = (src: string): Promise<HTMLImageElement | null> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = src;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (error) => {
      console.error(error);
      resolve(null);
    };
  });
};

const drawText = (
  context: CanvasRenderingContext2D,
  text: string,
  fontSize: number,
  x: number,
  y: number,
  color: string
) => {
  if (!text) {
    return;
  }
  context.font = "bold " + fontSize + "px 'Noto Sans JP'";
  context.textAlign = "left";
  context.textBaseline = "bottom";
  context.fillStyle = color;
  context.fillText(text, x, y);
};

const drawLine = (
  context: CanvasRenderingContext2D,
  color: string,
  x: number,
  y: number,
  width: number
) => {
  context.beginPath();
  context.moveTo(x, y);
  context.lineTo(x + width, y);
  context.strokeStyle = color;
  context.lineWidth = 3;
  context.stroke();
};

const drawQrCode = async (
  context: CanvasRenderingContext2D,
  url: string,
  x: number,
  y: number,
  size: number,
  iconSize: number
) => {
  const qrImage = await createQrImage(url, size);
  if (!qrImage) {
    return;
  }

  context.drawImage(qrImage, x, y, size, size);

  if (url.includes("line")) {
    const lineImage = await createImageAsync(`/line.png`);
    if (lineImage) {
      context.drawImage(
        lineImage,
        x + size / 2 - iconSize / 2,
        y + size / 2 - iconSize / 2,
        iconSize,
        iconSize
      );
    }
  }
};

const createQrImage = async (
  text: string,
  width: number
): Promise<HTMLImageElement | null> => {
  return createImageAsync(
    await QRCode.toDataURL(text, {
      margin: 2,
      width,
    })
  );
};
