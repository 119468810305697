import { Download } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import { AspectView } from "./AspectView";
import { StyleProvider } from "./StyleProvider";
import { createImageData, groupedImageConfigs } from "./createImageData";

const StyledCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const FillImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #dadada;
`;

type FormValues = {
  id: number;
  corporateName: string;
  title: string;
  name: string;
  email: string;
  tel: string;
  free: string;
  qrCode1: string;
  qrCode2: string;
};

function App() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [imageData, setImageData] = React.useState<string>("");

  const storageKey = "natee-background-generator-form-values";
  const storageValues = localStorage.getItem(storageKey);

  let defaultFormValues: FormValues = {
    id: 1,
    corporateName: "株式会社Natee",
    title: "代表取締役 CEO",
    name: "小島 領剣",
    email: "r.kojima@natee.co.jp",
    tel: "080-1234-0000",
    free: "自由入力項目",
    qrCode1: "https://line.me/ti/p/aaaaaaaaaa",
    qrCode2: "https://line.me/ti/p/aaaaaaaaaa",
  };
  if (storageValues) {
    defaultFormValues = JSON.parse(storageValues);
  }

  const [values, setValues] = React.useState<FormValues>(defaultFormValues);

  React.useEffect(() => {
    createImageData({ ...values }).then((imageData) => {
      setImageData(imageData);
    });

    localStorage.setItem(storageKey, JSON.stringify(values));
  }, [values]);

  const onDownload = React.useCallback(() => {
    const canvas = document.getElementById(`canvas`) as HTMLCanvasElement;
    const context = canvas.getContext("2d");
    if (!context) {
      return;
    }

    const a = document.createElement("a");

    a.href = canvas.toDataURL("image/jpg", 1.0);
    a.download = values.name ? `${values.name}のZoom背景.jpg` : "Zoom背景.jpg";
    a.click();
  }, [values]);

  return (
    <StyleProvider>
      <Container maxWidth={"lg"}>
        <Box p={matches ? 4 : undefined} py={matches ? undefined : 2}>
          <Paper>
            <Box p={matches ? 4 : 2}>
              <AspectView width={1921} height={1080}>
                <StyledCanvas id="canvas" width={1921} height={1080} />
                <FillImage src={imageData} alt={"背景1"} />
              </AspectView>

              <Box mt={4}>
                <FormControl fullWidth={true}>
                  <InputLabel id="background-id">背景</InputLabel>
                  <Select
                    value={values.id}
                    label="背景"
                    labelId="background-id"
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        id: Number(e.target.value),
                      }))
                    }
                    required={true}
                  >
                    {groupedImageConfigs.flatMap((group) => [
                      <ListSubheader key={`header-${group.label}`}>
                        {group.label}
                      </ListSubheader>,
                      ...group.configs.map((config) => (
                        <MenuItem key={config.id} value={config.id}>
                          {config.label}
                        </MenuItem>
                      )),
                    ])}
                  </Select>
                </FormControl>
              </Box>

              <Box mt={4}>
                <FormControl fullWidth={true}>
                  <TextField
                    label={"会社名"}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        corporateName: e.target.value,
                      }))
                    }
                    value={values.corporateName}
                    required={true}
                  />
                </FormControl>
              </Box>

              <Box mt={4}>
                <FormControl fullWidth={true}>
                  <TextField
                    label={"役職名"}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        title: e.target.value,
                      }))
                    }
                    value={values.title}
                    required={true}
                  />
                </FormControl>
              </Box>

              <Box mt={4}>
                <FormControl fullWidth={true}>
                  <TextField
                    label={"お名前"}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        name: e.target.value,
                      }))
                    }
                    value={values.name}
                    required={true}
                  />
                </FormControl>
              </Box>

              <Box mt={4}>
                <FormControl fullWidth={true}>
                  <TextField
                    label={"メールアドレス"}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        email: e.target.value,
                      }))
                    }
                    value={values.email}
                    required={true}
                  />
                </FormControl>
              </Box>

              <Box mt={4}>
                <FormControl fullWidth={true}>
                  <TextField
                    label={"電話番号"}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        tel: e.target.value,
                      }))
                    }
                    value={values.tel}
                  />
                </FormControl>
              </Box>

              <Box mt={4}>
                <FormControl fullWidth={true}>
                  <TextField
                    label={"フリー項目"}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        free: e.target.value,
                      }))
                    }
                    value={values.free}
                  />
                </FormControl>
              </Box>

              <Box mt={4}>
                <FormControl fullWidth={true}>
                  <TextField
                    label={"QRコード1"}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        qrCode1: e.target.value,
                      }))
                    }
                    value={values.qrCode1}
                  />
                </FormControl>
              </Box>

              <Box mt={4}>
                <FormControl fullWidth={true}>
                  <TextField
                    label={"QRコード2"}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        qrCode2: e.target.value,
                      }))
                    }
                    value={values.qrCode2}
                  />
                </FormControl>
              </Box>

              {matches && (
                <Box mt={4}>
                  <FormControl fullWidth={true}>
                    <Button
                      variant={"contained"}
                      onClick={onDownload}
                      startIcon={<Download />}
                    >
                      作成したZoom背景をダウンロードする
                    </Button>
                  </FormControl>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Container>
    </StyleProvider>
  );
}

export default App;
